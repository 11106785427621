import React, { useContext } from "react";
import appContext from "../../context/appContext";
import reports from "../../assets/reports.json";
import geoDict from "../../assets/geoDict.json";
import { ReportLevelAlerts } from "../DataAlerts";
import ReportNotAvailableButton from "./ReportNotAvailableButton";

import { useViewport } from "@headwaters-economics/web-shared";
import { Box, Button, Heading, Grid, Text, DropButton, Image } from "grommet";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/pro-regular-svg-icons";
import { faExternalLinkAlt } from "@fortawesome/pro-light-svg-icons";

import _countBy from "lodash/countBy";

function ReportListStandard({ cat, anchor }) {
  const { associatedCounty, selectedLocations } = useContext(appContext);
  const { screenSize } = useViewport();

  const ReportHeader = ({ title, anchor }) => {
    return (
      <Box
        direction="row"
        justify="between"
        align="baseline"
        height={{ min: "fit-content" }}
        fill="horizontal"
      >
        <Box margin=".5em 0">
          <Heading id={anchor} level="3" size="larger" margin="none">
            {title}{" "}
            <Text weight={100} size="larger" className="condensed">
              Reports
            </Text>
          </Heading>
        </Box>
        {screenSize === "mobile" ? null : (
          <Box direction="row" gap="xsmall">
            <Text size="xsmall">Available for:</Text>
            {selectedLocations.length > 1 ? (
              <Box width="90px">
                <Text weight={900} size="xsmall" textAlign="center">
                  Selected Locations
                </Text>
              </Box>
            ) : !associatedCounty ? (
              <Box width="90px">
                <Text
                  weight={900}
                  size="xsmall"
                  textAlign="center"
                  truncate="tip"
                >
                  {selectedLocations.length > 0
                    ? ["county", "place"].includes(
                        selectedLocations[0].geo_level
                      )
                      ? selectedLocations[0].label.slice(0, -4)
                      : selectedLocations[0].label
                    : ""}
                </Text>
              </Box>
            ) : (
              <Grid columns={["120px", "120px"]}>
                <Box>
                  <Text
                    weight={900}
                    size="xsmall"
                    textAlign="center"
                    truncate="tip"
                  >
                    {selectedLocations.length > 0
                      ? ["county", "place"].includes(
                          selectedLocations[0].geo_level
                        )
                        ? selectedLocations[0].label.slice(0, -4)
                        : selectedLocations[0].label
                      : null}
                  </Text>
                </Box>

                <Box>
                  <Text weight={900} size="xsmall" textAlign="center">
                    {associatedCounty.name.slice(0, -4)}
                  </Text>
                </Box>
              </Grid>
            )}
          </Box>
        )}
      </Box>
    );
  };

  const AppButton = ({ url, appDescription, appLogo }) => {
    return (
      <Box
        background="light-2"
        border={{ color: "light-3", size: "xsmall" }}
        pad={{ horizontal: "xsmall" }}
        round="xsmall"
      >
        <DropButton
          reverse={true}
          icon={
            <FontAwesomeIcon
              color="#0D4D80"
              icon={faExternalLinkAlt}
              size="xs"
            />
          }
          plain
          size="small"
          gap="xsmall"
          label={
            <Text color="heDarkBlue" size="xsmall">
              Custom Tool
            </Text>
          }
          dropContent={
            <Box
              background="light-3"
              pad="5px"
              direction="row"
              gap="xsmall"
              height="fit-content"
              width="fit-content"
              align="center"
              justify="between"
            >
              <Box height="50px" width="50px">
                <Image src={appLogo} fill />
              </Box>
              <Box
                direction="row"
                align="center"
                gap="xsmall"
                pad={{ right: "small" }}
              >
                <Text>{appDescription},</Text>
                <Button
                  gap="xsmall"
                  target="_blank"
                  color="brand"
                  label="View tool"
                  plain
                  href={url}
                  icon={
                    <FontAwesomeIcon
                      color="#0D4D80"
                      icon={faExternalLinkAlt}
                      size="xs"
                    />
                  }
                />
              </Box>
            </Box>
          }
        />
      </Box>
    );
  };

  const ReportCard = ({
    name,
    summary,
    subCounty,
    url,
    template,
    appDescription,
    appLogo,
  }) => {
    const buttonLayout =
      !associatedCounty || selectedLocations.length > 1
        ? "single"
        : screenSize === "mobile"
        ? "doubleWithDropdown"
        : "double";
    const excludedDueToMaxResults = selectedLocations.length > 50;
    const excludedDueToInvalidGeos =
      selectedLocations.filter(
        (loc) => loc.validReports && !loc.validReports.includes(template)
      ).length > 0;
    const excludedDueToMixedGeoTypes =
      Object.keys(_countBy(selectedLocations, "geo_level")).length > 1 &&
      !reports.find((report) => report.template === template)
        .multipleGeoTypesAllowed;

    // throw an error for Connecticut counties for reports with BEA data
    const connecticutCounties = [
      "9011",
      "9001",
      "9007",
      "9009",
      "9015",
      "9013",
      "9005",
      "9003",
    ];
    const connecticutCountyEquivs = [
      "9110",
      "9170",
      "9180",
      "9130",
      "9190",
      "9140",
      "9120",
      "9160",
      "9150",
    ];
    const excludedDueToConnecticutCounties =
      [
        "indicators",
        "publiclands",
        "trends",
        "usfws",
        "blm",
        "fs",
        "agriculture",
        "government",
        "mining",
        "nonlabor",
        "services",
        "timber",
        "tourism",
      ].includes(template) &&
      selectedLocations.some(
        (reportLocation) =>
          connecticutCounties.includes(reportLocation.id) ||
          connecticutCountyEquivs.includes(reportLocation.id)
      );
    
    let invalidTypeWarning;
    if (excludedDueToInvalidGeos) {
      const validTypes = reports
        .find((r) => r.template === template)
        .geoTypes.map((geoType) => geoDict[geoType].labelAlt2);
      invalidTypeWarning = (
        <Text>
          {
            "Some selected geographies are not supported by this report. Supported geography types include: "
          }
          {validTypes.slice(0, -1).map((type, i) => {
            return (
              <React.Fragment key={i}>
                <i>{type}</i>
                {i < validTypes.length - 3
                  ? ", "
                  : i < validTypes.length - 2
                  ? ", and "
                  : "."}
              </React.Fragment>
            );
          })}
        </Text>
      );

      // invalidTypeWarning += validTypes.slice(0, -1).join(', ')+' and '+validTypes.slice(-1)
    }

    return (
      <Box
        elevation="xsmall"
        background="#ffffff"
        pad={{ vertical: "xsmall", right: "none", left: "small" }}
        direction="row"
        gap="xsmall"
        align="center"
        justify="between"
        margin={{ bottom: "xsmall" }}
        height={{ min: "fit-content" }}
      >
        <Box width="calc(100% - 100px)">
          <Box direction="row" justify="between" align="center" gap="xxsmall">
            <Box>
              <Box direction="row" align="center">
                <Heading
                  level="4"
                  margin={{ right: "xsmall", vertical: "none" }}
                >
                  {name}
                </Heading>
                <ReportLevelAlerts report={template} />
                {url && (
                  <AppButton
                    url={url}
                    appDescription={appDescription}
                    appLogo={appLogo}
                  />
                )}
              </Box>
              <Text size="small">{summary}</Text>
            </Box>
          </Box>
        </Box>

        {buttonLayout === "single" ? (
          <Box width="90px">
            {excludedDueToMaxResults ? (
              <ReportNotAvailableButton notificationText="Report only supports up to 50 geographies. Contact us (https://headwaterseconomics.org/contact/) if you need to run a custom report." />
            ) : excludedDueToMixedGeoTypes ? (
              <ReportNotAvailableButton notificationText="This report does not support geographies of different types." />
            ) : excludedDueToConnecticutCounties ? (
              <ReportNotAvailableButton notificationText="In 2022, the Census Bureau adopted Connecticut's nine planning regions as county-equivalent geographic units for purposes of collecting, tabulating, and disseminating statistical data, replacing the eight counties which ceased to function as governmental and administrative entities in 1960. Reports that have data over time for these geographies are not currently available." />
            ) : excludedDueToInvalidGeos ? (
              <ReportNotAvailableButton notificationText={invalidTypeWarning} />
            ) : (
              <DownlaodReportButton
                a11yTitle={
                  "Download " +
                  name +
                  "Report for " +
                  (selectedLocations.length > 0
                    ? selectedLocations[0].label
                    : null)
                }
                reportLocations={selectedLocations}
                template={template}
                reportName={name}
              />
            )}
          </Box>
        ) : buttonLayout === "double" ? (
          <Grid columns={["120px", "120px"]}>
            <Box>
              {subCounty && template !== "wildfire" ? (
                <DownlaodReportButton
                  a11yTitle={
                    "Download " +
                    name +
                    "Report for " +
                    (selectedLocations > 0 ? selectedLocations[0].label : null)
                  }
                  reportLocations={selectedLocations}
                  template={template}
                  reportName={name}
                />
              ) : (
                <ReportNotAvailableButton notificationText="Download not available below county level" />
              )}
            </Box>
            <Box>
              <DownlaodReportButton
                a11yTitle={
                  "Download " +
                  name +
                  "Report for " +
                  (associatedCounty ? associatedCounty.label : null)
                }
                reportLocations={[associatedCounty]}
                template={template}
                reportName={name}
              />
            </Box>
          </Grid>
        ) : buttonLayout === "doubleWithDropdown" ? (
          <Box width="120px">
            <DropButton
              alignSelf="center"
              plain
              icon={<FontAwesomeIcon icon={faDownload} size="lg" />}
              dropAlign={{ top: "top", right: "right" }}
              dropContent={
                <Box direction="row" fill width={{ min: "66vw" }} pad="small">
                  <Box basis="1/2" justify="between">
                    <Text weight={900} size="xsmall" textAlign="center">
                      {selectedLocations.length > 0
                        ? selectedLocations[0].label.slice(0, -4)
                        : null}
                    </Text>
                    {subCounty && template !== "wildfire" ? (
                      <DownlaodReportButton
                        a11yTitle={
                          "Download " +
                          name +
                          "Report for " +
                          (selectedLocations.length > 0
                            ? selectedLocations[0].label
                            : null)
                        }
                        reportLocations={selectedLocations}
                        template={template}
                        reportName={name}
                      />
                    ) : (
                      <ReportNotAvailableButton notificationText="Download not available below county level" />
                    )}
                  </Box>

                  <Box basis="1/2">
                    <Text weight={900} size="xsmall" textAlign="center">
                      {associatedCounty.name.slice(0, -4)}
                    </Text>
                    <DownlaodReportButton
                      a11yTitle={
                        "Download " +
                        name +
                        "Report for " +
                        (associatedCounty ? associatedCounty.label : null)
                      }
                      reportLocations={[associatedCounty]}
                      template={template}
                      reportName={name}
                      // associatedCounty={associatedCounty}
                    />
                  </Box>
                </Box>
              }
            />
          </Box>
        ) : null}
      </Box>
    );
  };

  function ReportCards() {
    return (
      <>
        <Box margin={{ bottom: "medium" }}>
          {reports
            .filter((report) => report.category === cat)
            .map((filteredReport) => (
              <ReportCard
                key={filteredReport.template}
                name={filteredReport.name}
                summary={filteredReport.summary}
                subCounty={filteredReport.subCounty}
                url={filteredReport.url}
                template={filteredReport.template}
                appDescription={filteredReport.appDescription}
                appLogo={filteredReport.applogo}
              />
            ))}
        </Box>
      </>
    );
  }

  return (
    <>
      <ReportHeader title={cat} anchor={anchor} />
      <ReportCards />
    </>
  );
}

const DownlaodReportButton = ({
  a11yTitle,
  reportLocations,
  template,
  reportName,
}) => {
  const { setDownloadModalProps } = useContext(appContext);
  return (
    <Button
      a11yTitle={a11yTitle}
      color="heBlue-4"
      margin={{ horizontal: "auto" }}
      icon={<FontAwesomeIcon icon={faDownload} size="lg" />}
      onClick={() => {
        setDownloadModalProps({
          reportLocations: reportLocations,
          isVisible: true,
          template: template,
          reportName: reportName,
        });
      }}
    />
  );
};

export default ReportListStandard;
