import React, { useContext } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.scss";
import AppProvider from "./context/AppProvider";
import appContext from "./context/appContext";
import { ViewportProvider, useViewport, HeMainTheme } from "@headwaters-economics/web-shared";
import GoogleAnalytics from "@headwaters-economics/web-shared/components/GoogleAnalyticsWithRouter";
import { Grommet, Box } from "grommet";
import { deepMerge } from "grommet/utils";
import StartScreen from "./screens/StartScreen";
import MainScreen from "./screens/MainScreen";
import colors from "./assets/colors";
// import FeedbackModal from "./components/FeedbackModal";

// import GoogleAnalytics from "@headwaters-economics/web-shared/components/GoogleAnalyticsWithRouter";

const epsExplore = deepMerge(HeMainTheme, {
  global: {
    colors: {
      brand: colors.brand,
      brandLight: colors.brandLight,
      accent: colors.accent,
      error: colors.error,
      warning: colors.warning,
    },
    font: {
      family: "Source Sans Pro, sans-serif",
    },
  },
});

function App() {
  return (
    <Router>
      <AppProvider>
        <ViewportProvider>
          <GrommetContainer />
        </ViewportProvider>
      </AppProvider>
    </Router>
  );
}

const GrommetContainer = () => {
  const { appWidth } = useViewport();
  const { grommetRoot } = useContext(appContext);
  return (
    <Grommet
      className="app"
      full={appWidth < 1000 ? false : true}
      theme={epsExplore}
    >
      <GoogleAnalytics />
      <Box fill ref={grommetRoot}>
        {/* <FeedbackModal /> */}
        <Routes>
          <Route path="/" exact element={<StartScreen />}></Route>
          <Route exact path="/:urlIDs" element={<MainScreen />}></Route>
        </Routes>
      </Box>
    </Grommet>
  );
};

export default App;
