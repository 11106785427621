import React, { useContext, useEffect, useState } from "react";
import appContext from "../context/appContext";
import { Box, Select, Text, Heading, Stack, Meter, Anchor } from "grommet";
import colors from "../assets/colors";
import styled from "styled-components";
import { useViewport } from "@headwaters-economics/web-shared";
import EditBenchmarkButton from "./EditBenchmarkButton";

const StyledSelect = styled(Select)`
  padding: 0.25em;
`;

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
});

const populationFormatter = new Intl.NumberFormat("en-US", {
  style: "decimal",
  maximumFractionDigits: 0,
  notation: "standard",
});

const percentFormatter = new Intl.NumberFormat("en-US", {
  style: "percent",
  maximumFractionDigits: 0,
});

const metrics = [
  { label: "Population", metric: "population", formatter: populationFormatter },
  {
    label: "Median Household Income",
    metric: "income",
    formatter: currencyFormatter,
  },
  {
    label: "High School Graduates",
    metric: "propHsGrad",
    formatter: percentFormatter,
  },
  {
    label: "People of Color and Hispanics",
    metric: "propMinority",
    formatter: percentFormatter,
  },
];

const QuickComparison = () => {
  const {
    quickFactsData,
    quickFactsDataStatus,
    selectedLocations,
    benchmarkGeo,
  } = useContext(appContext);
  const [selectedMetric, set_selectedMetric] = useState(metrics[1].label);
  const { screenSize } = useViewport();
  const [chartData, set_chartData] = useState(null);
  const [chartWarning, set_chartWarning] = useState(null);

  useEffect(() => {
    if (
      !quickFactsData ||
      selectedLocations.length === 0 ||
      !benchmarkGeo ||
      quickFactsDataStatus !== "fetched"
    )
      return;

    const metricDetails = metrics.find((m) => m.label === selectedMetric);
    let data = [];
    let geosWithoutData = [];
    selectedLocations.forEach((element) => {
      if (
        !(element.id in quickFactsData) ||
        quickFactsData[element.id] === "fetching"
      ) {
        // just wait for it
      } else if (
        element.id in quickFactsData &&
        metricDetails.metric in quickFactsData[element.id]
      ) {
        data.push({
          id: element.id,
          name: element.name,
          barColor: colors.geos_barFill,
          value: quickFactsData[element.id][metricDetails.metric],
        });
      } else {
        geosWithoutData.push(element.name);
      }
    });
    if (selectedMetric !== "Population") {
      data.push({
        id: benchmarkGeo.id,
        name: benchmarkGeo.name,
        barColor: "#031E33",
        value: quickFactsData[benchmarkGeo.id]
          ? quickFactsData[benchmarkGeo.id][metricDetails.metric]
          : 0,
        benchmark: true,
      });
    }
    set_chartData(data);
    if (geosWithoutData.length === 0) {
      if (chartWarning) {
        set_chartWarning(null);
      }
    } else {
      const warningText =
        "*" +
        (geosWithoutData.length === 1
          ? geosWithoutData[0]
          : geosWithoutData.slice(0, -1).join(", ") +
            " and " +
            geosWithoutData.slice(-1)) +
        (geosWithoutData.length === 1 ? " is " : " are ") +
        "missing data for this metric";
      if (chartWarning !== warningText) {
        set_chartWarning(warningText);
      }
    }
  }, [
    quickFactsData,
    selectedLocations,
    benchmarkGeo,
    selectedMetric,
    quickFactsDataStatus,
    chartWarning,
  ]);

  const spacing = screenSize === "mobile" ? "medium" : "small";

  const QuickFactCompareBars = ({ data }) => {
    let maxBar;
    function maxValue() {
      return data.map((d) => d.value);
    }
    maxBar = Math.max(...maxValue()) * 1.25;
    const barData = data.map((value, i) => {
      let rowBG;
      let labelColor;
      let pad = 0;
      if (i % 2) {
        rowBG =
          "linear-gradient(0deg, rgba(250,250,250,0) 30%, rgba(250,250,250,0.2) 50%, rgba(250,250,250,0) 70%)";
      } else {
        rowBG =
          "linear-gradient(0deg, rgba(250,250,250,0) 30%, rgba(250,250,250,0.1) 50%, rgba(250,250,250,0) 70%)";
      }
      if (value.value < maxBar / 5.25) {
        labelColor = "#222";
        pad = (value.value / maxBar) * 100 + "%";
      } else {
        labelColor = "#fff";
      }
      return (
        <Stack key={value.id} margin="none">
          <Box style={{ background: rowBG }}>
            <Meter
              width="100%"
              type="bar"
              thickness="medium"
              background="none"
              color="heBlue-3"
              max={maxBar}
              values={[
                {
                  value: value.value,
                  label: value.name,
                  color: value.barColor,
                },
              ]}
            />
          </Box>

          <Box
            fill
            direction="row"
            justify="between"
            align="center"
            pad={
              pad !== 0
                ? { left: "calc(" + pad + " + .25em)", right: ".25em" }
                : { horizontal: ".25em" }
            }
          >
            <Box direction="row" height="18px" align="center" gap="xsmall">
              <Text
                size="8pt"
                color={labelColor}
                weight="normal"
                className={value.benchmark === true ? "text-shadow" : ""}
              >
                {value.name}
                {value.benchmark === true ? (
                  <Text size="6pt">- comparison</Text>
                ) : (
                  ""
                )}
              </Text>
              {value.benchmark === true && <EditBenchmarkButton />}
            </Box>

            <Text size="11pt" weight="normal">
              {metrics
                .find((m) => m.label === selectedMetric)
                .formatter.format(value.value)}
            </Text>
          </Box>
        </Stack>
      );
    });
    return (
      <Box justify="start" gap="2px" pad={spacing}>
        {barData}
      </Box>
    );
  };

  if (!chartData || !selectedLocations.length === 0) return null;
  return (
    // <Box width={{ max: "400px" }} pad="xsmall" elevation="medium" animation="fadeIn" background="light-1">
    <Box
      direction="column"
      justify="start"
      elevation="medium"
      animation="fadeIn"
      fill
      background="#c9c9c9"
    >
      <Box
        direction="row"
        justify="between"
        gap="small"
        background="brand"
        pad={{ top: "3px", bottom: "4px", horizontal: "xsmall" }}
        height={{ min: "fit-content" }}
        align="end"
      >
        <Heading
          size="large"
          direction="row"
          align="baseline"
          margin={{ top: "4px", bottom: "0" }}
        >
          <Text size="large" weight="bold">
            Q
          </Text>
          <Text size="small" weight="normal">
            UICK
          </Text>
          <Text size="large" weight="bold">
            F
          </Text>
          <Text size="small" weight="normal">
            ACTS
          </Text>
        </Heading>
        <Box flex="grow">
          <StyledSelect
            options={metrics.map((m) => m.label)}
            value={selectedMetric}
            gap="xsmall"
            size="small"
            pad="none"
            onChange={(evt) => set_selectedMetric(evt.value)}
          />
        </Box>
      </Box>
      <Box fill overflow="auto">
        <QuickFactCompareBars data={chartData} />
        {chartWarning && (
          <Box pad={{ horizontal: "small" }}>
            <Text textAlign="center" size="xsmall" weight="bold">
              {chartWarning}
            </Text>
          </Box>
        )}
      </Box>
      <Box
        pad={{ horizontal: "small" }}
        height={{ min: "fit-content" }}
        direction="row"
        gap="xsmall"
      >
        <Text size="xsmall">Sources: Census Bureau</Text>
        <Anchor
          size="xsmall"
          label="How to cite these data"
          href="https://headwaterseconomics.org/tools/economic-profile-system/about-eps/#faq"
        />
      </Box>
    </Box>
  );
};

export default QuickComparison;
