import React from "react";
import { Box, Button } from "grommet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesSquare } from "@fortawesome/pro-regular-svg-icons";
import {
  faSpinner,
  faThumbsDown,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";

const ReportNotAvailableButton = ({ notificationText, iconType }) => {
  const faIcon =
    iconType === "errored"
      ? faThumbsDown
      : iconType === "downloaded"
      ? faThumbsUp
      : iconType === "downloading"
      ? faSpinner
      : faTimesSquare;

  return (
    <Button
      a11yTitle={typeof notificationText == 'string' ? notificationText : "Report is not available for the selected geographies."}
      margin={{ horizontal: "auto" }}
      color="#c9c9c9"
      icon={
        <FontAwesomeIcon icon={faIcon} size="lg" spin={faIcon === faSpinner} />
      }
      // tip={notificationText}
      tip={{
        plain: true,
        content: (
          <Box
            pad="xxsmall"
            elevation="small"
            round="xsmall"
            margin="xsmall"
            background={"#EDEDED"}
            width={{ max: "large" }}
          >
            {notificationText}
          </Box>
        ),
      }}
    />
  );
};

export default ReportNotAvailableButton;
