import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import appContext from "../context/appContext";
import {
  Box,
  Button,
  Grid,
  Main,
  Text,
  Anchor,
  Paragraph,
  DropButton,
} from "grommet";
import { FormDown } from "grommet-icons";
import LocationPicker from "@headwaters-economics/web-shared/components/LocationPicker";
// import { SearchHeaderCompact } from "../shared/components/HeLocationPicker/HeLocationPicker";
import AppHeader from "../components/AppHeader";
import AppFooter from "../components/AppFooter";
import AppMenu from "../components/AppMenu";
import AppMain from "../components/AppMain";
import DownloadModal from "../components/DownloadModal";
import { useViewport } from "@headwaters-economics/web-shared";
import heLogoIcon from "../assets/images/HE-LogoIcon.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import { faUndo } from "@fortawesome/pro-solid-svg-icons";
import { GeoLevelAlerts } from "../components/DataAlerts";

const legacyLink = "/apps/legacy-economic-profile-system/";

const MainScreen = () => {
  const {
    setSearchLocation,
    searchedLocation,
    selectedLocations,
    removeSelectedLocationId,
    isMultipleGeosViewVisible,
  } = useContext(appContext);
  const { appWidth } = useViewport();
  const numLocations = selectedLocations.length;

  const SelectedLocationsDrop = ({ onClose }) => (
    <Box
      height={{ max: "medium" }}
      pad="xxsmall"
      style={{ display: "inline" }}
      overflow={{ vertical: "auto" }}
    >
      <Box>
        {selectedLocations.map((selectedLocation, i) => {
          let rowBG;
          if (i % 2) {
            rowBG = "light-2";
          } else {
            rowBG = "none";
          }
          return (
            <Box
              key={selectedLocation.id}
              pad="xsmall"
              direction="row"
              justify="between"
              background={rowBG}
              height="fit-content"
              margin="0"
            >
              <Text size={"xxsmall"}>
                {selectedLocation.label || selectedLocation.name}
              </Text>
              <Button
                plain
                icon={<FontAwesomeIcon icon={faTrash} />}
                key={selectedLocation.id}
                onClick={() => {
                  removeSelectedLocationId(selectedLocation.id);
                }}
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );

  SelectedLocationsDrop.propTypes = {
    onClose: PropTypes.func.isRequired,
  };

  const SelectedLocationsDropButton = () => {
    const [Open, setOpen] = useState();
    const onOpen = () => {
      setOpen(true);
    };
    const onClose = () => {
      setOpen(false);
    };

    return (
      <Box
        border={{ color: "light-3", size: "xsmall" }}
        fill="vertical"
        align="center"
        direction="row"
        width="large"
        pad={{ horizontal: "small" }}
      >
        <DropButton
          plain
          reverse
          gap="xsmall"
          label={
            <Text className="condensed" weight="bold" size="xlarge">
              Locations Selected{" "}
              <span className="greenpulse">({numLocations})</span>
            </Text>
          }
          open={Open}
          onOpen={onOpen}
          onClose={onClose}
          icon={<FormDown color="heBlue-4" size="32px" />}
          dropContent={<SelectedLocationsDrop onClose={onClose} />}
          dropAlign={{ top: "bottom", left: "left" }}
        />
      </Box>
    );
  };
  return appWidth < 1000 ? (
    <>
      <AppHeader
        gridArea="header"
        leftSideContent={
          <Box direction="column" gap="xsmall" align="center">
            <Box direction="row" align="center">
              <Box
                border={{ side: "right", color: "light-3", size: "xsmall" }}
                pad={{ right: "6px", left: "3px" }}
                align="center"
                width={{ min: "fit-content" }}
              >
                <Button plain href="/">
                  <img
                    className="logo-icon"
                    height="30px"
                    width="30px"
                    src={heLogoIcon}
                    alt="Headwaters logo"
                  />
                </Button>
              </Box>
              <Box
                direction="row"
                pad={{ left: "4px", right: "small" }}
                margin={{ bottom: "-2px" }}
                width={{ min: "fit-content" }}
              >
                <Button plain href="/apps/economic-profile-system">
                  <Text className="logo-text">
                    <Text weight="bold">Economic</Text> Profile System
                  </Text>
                </Button>
              </Box>
            </Box>
          </Box>
        }
        menu={
          <AppMenu
            customItems={[
              {
                label: "About the Economic Profile System",
                href: "/tools/economic-profile-system/about-eps/",
              },
              {
                label: "Legacy Version",
                href: "/apps/legacy-economic-profile-system/",
              },
            ]}
          />
        }
      />
      <Box pad={{ horizontal: "xsmall" }} height="50px" justify="center">
        {selectedLocations.length > 1 ? (
          <SelectedLocationsDropButton />
        ) : (
          <LocationPicker
            // showIcon={false}
            // searchLocation={selectedLocations[0]}
            width="medium"
            border
            round="xsmall"
            onSelection={setSearchLocation}
            geoTypes={[
              "he-place",
              "he-county",
              "he-state",
              "he-nation",
              "he-tribal",
            ]}
          />
        )}
        <GeoLevelAlerts />
      </Box>
      <Box className="app-main" elevation="small" flex={false}>
        <AppMain />
        <DownloadModal />
      </Box>

      <AppFooter />
    </>
  ) : (
    <Grid
      areas={[
        { name: "header", start: [0, 0], end: [2, 0] },
        { name: "main", start: [0, 1], end: [2, 2] },
        { name: "footer", start: [0, 3], end: [2, 3] },
      ]}
      columns={["flex", "flex", "flex"]}
      rows={["auto", "auto", "1fr", "60px"]}
      gap="none"
      fill
    >
      <AppHeader
        gridArea="header"
        height="60px"
        leftSideContent={
          <Box direction="row" align="center" width="80vw" height="50px">
            <Box
              border={{ side: "right", color: "light-3", size: "xsmall" }}
              pad={{ right: "8px" }}
              align="center"
              width={{ min: "fit-content" }}
            >
              <Button plain href="/">
                <img
                  className="logo-icon"
                  height="50px"
                  width="50px"
                  src={heLogoIcon}
                  alt="Headwaters logo"
                />
              </Button>
            </Box>
            <Button plain href="/apps/economic-profile-system">
              <Box
                direction="column"
                pad={{ left: "8px", right: "small" }}
                margin={{ bottom: "-2px" }}
                width={{ min: "fit-content" }}
              >
                <Text className="logo-text" weight="bold">
                  Economic
                </Text>
                <Text className="logo-text">Profile</Text>
                <Text className="logo-text">System</Text>
              </Box>
            </Button>
            {selectedLocations.length > 1 ? (
              <SelectedLocationsDropButton />
            ) : (
              <LocationPicker
                width="medium"
                border
                round="xsmall"
                onSelection={setSearchLocation}
                geoTypes={[
                  "he-place",
                  "he-county",
                  "he-state",
                  "he-nation",
                  "he-tribal",
                ]}
              />
            )}
            <GeoLevelAlerts />
            {isMultipleGeosViewVisible && searchedLocation && (
              <Box>
                <Button
                  margin={{ horizontal: "medium" }}
                  plain
                  label="RESET"
                  color="#777"
                  icon={<FontAwesomeIcon icon={faUndo} size="xs" />}
                  border={{ size: "small" }}
                  gap="xsmall"
                  tip={
                    selectedLocations.length > 1
                      ? "Reset to " +
                        (searchedLocation.name || searchedLocation.label)
                      : "Reset map"
                  }
                  onClick={() => setSearchLocation(searchedLocation)}
                />
              </Box>
            )}
          </Box>
        }
        // rightSideContent={<Button plain icon={<FontAwesomeIcon icon={faExternalLink} size="1x" />} label={<Text>Legacy Version</Text>} href={legacyLink} gap="xsmall" color="heBlue-2" />}
        menu={
          <AppMenu
            customItems={[
              {
                label: "About the Economic Profile System",
                href: "/tools/economic-profile-system/about-eps/",
              },
              { label: "Legacy Version", href: legacyLink },
            ]}
          />
        }
      />

      <Main className="app-main" gridArea="main" elevation="medium">
        <AppMain />
        <DownloadModal />
      </Main>

      <AppFooter
        gridArea="footer"
        leftSideContent={
          <Box>
            <Paragraph size="xsmall" margin="none" responsive={false} fill>
              This free tool is nationally available thanks to generous support
              from the{" "}
              <Anchor
                href="https://www.blm.gov/"
                label="Bureau of Land Management"
              />
              ,{" "}
              <Anchor
                href="https://www.fs.usda.gov/"
                label="USDA Forest Service"
              />
              ,{" "}
              <Anchor
                href="https://www.fws.gov/"
                label="U.S. Fish &amp; Wildlife Service"
              />
              , <Anchor href="https://mapbox.com/" label="Mapbox" />, and{" "}
              <Anchor
                href="https://murdocktrust.org/"
                label="M.J. Murdock Charitable Trust"
              />
              .
            </Paragraph>
          </Box>
        }
      />
    </Grid>
  );
};

export default MainScreen;
