import React, { useContext } from "react";

import geoDict from "../assets/geoDict.json";
import appContext from "../context/appContext";
import LocationPicker from "@headwaters-economics/web-shared/components/LocationPicker";

import styled from "styled-components";
import { Box, Select, Text } from "grommet";
import { Down } from "grommet-icons";

const StyledSelect = styled(Select)`
  padding: 0.25em;
`;

const selectOptions = [
  "place",
  "county",
  "state",
  "tract",
  "cd",
  "tribal",
  "portion",
  "cousub",
  "nation",
].map((geoType) => ({ value: geoType, label: geoDict[geoType].labelAlt }));

const MapControlPanel = () => {
  const { activeMapLayer, setActiveMapLayer, addSelectedLocationId } =
    useContext(appContext);

  return (
    <Box
      pad="xsmall"
      direction="column"
      gap="xxsmall"
      align="start"
      background={{ color: "light-1", opacity: 0.9 }}
      round="3px"
      width={{ max: "large" }}
      className="map-control"
    >
      <Box
        direction="row-responsive"
        align="center"
        justify="start"
        gap="xxsmall"
      >
        <Text size="small">Use the map or search to add a</Text>
        <StyledSelect
          options={selectOptions}
          value={activeMapLayer}
          labelKey="label"
          icon={<Down size="small" />}
          onChange={({ value: nextValue }) => setActiveMapLayer(nextValue)}
          size="small"
          valueKey={{ key: "value", reduce: true }}
          pad="none"
        />
      </Box>

      <LocationPicker
        onSelection={(location) => {
          addSelectedLocationId(location.id);
        }}
        placeholder={"Which " + geoDict[activeMapLayer].labelAlt + "?"}
        geoTypes={["he-" + activeMapLayer]}
        fill='horizontal'
        border
        round='xsmall'
      />
    </Box>
  );
};

export default MapControlPanel;
