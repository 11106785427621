import { useEffect, useRef, useReducer } from "react";
import { getData } from "@headwaters-economics/web-shared";
import _merge from "lodash/merge";
import _includes from "lodash/includes";
import _isEqual from "lodash/isEqual";

const useDataAlerts = ({ geoIDs }) => {
  const cache = useRef({});
  const initialState = {
    status: "idle",
    error: null,
    dataAlerts: {},
  };

  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "FETCHING":
        if (state.status === "fetching") return state;
        return { ...initialState, status: "fetching" };
      case "FETCHED":
        if (
          state.status === "fetched" &&
          _isEqual(state.dataAlerts, action.payload)
        )
          return state;
        return {
          ...initialState,
          status: "fetched",
          dataAlerts: action.payload,
        };
      case "FETCH_ERROR":
        return { ...initialState, status: "error", error: action.payload };
      case "RESET":
        return initialState;
      default:
        return state;
    }
  }, initialState);

  useEffect(() => {
    if (!geoIDs || !geoIDs[0]) {
      dispatch({ type: "RESET" });
      return;
    }
    const fetchData = async () => {
      let missingIDs = new Set();
      let out = {};
      geoIDs.forEach((id) => {
        if (cache.current && cache.current[id]) {
          out[id] = cache.current[id];
        } else {
          missingIDs.add(id);
        }
      });
      if (missingIDs.size === 0) {
        dispatch({
          type: _includes(cache.current, "fetching") ? "FETCHING" : "FETCHED",
          payload: out,
        });
      } else {
        missingIDs.forEach((id) => (cache.current[id] = "fetching"));
        dispatch({ type: "FETCHING" });
        try {
          const newData = await getData("fetchDataAlerts", {
            ids: [...missingIDs],
          });
          // add the new recods to the cache
          _merge(cache.current, newData);
          dispatch({ type: "FETCHED", payload: _merge(out, newData) });
        } catch (error) {
          dispatch({ type: "FETCH_ERROR", payload: error.message });
          throw error;
        }
      }
    };

    fetchData();
  }, [geoIDs]);

  return state;
};

export default useDataAlerts;
