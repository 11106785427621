import React, { useContext } from "react";
import appContext from "../../context/appContext";

import reports from "../../assets/reports.json";
import { ReportLevelAlerts } from "../DataAlerts";

import { Box, Button, Text, DropButton } from "grommet";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faDownload } from "@fortawesome/pro-regular-svg-icons";
import ReportNotAvailableButton from "./ReportNotAvailableButton";
import _countBy from "lodash/countBy";

function ReportListCompact({
  onDownloadReport,
  category,
  previouslyDownloadedReports,
  erroredReports,
  downloadingReports,
}) {
  const { downloadModalProps } = useContext(appContext);

  const MicroCard = ({
    name,
    summary,
    template,
    previouslyDownloaded,
    errored,
    downloading,
  }) => {
    const excludedDueToInvalidGeos =
      downloadModalProps.reportLocations.filter(
        (loc) => !loc.validReports.includes(template)
      ).length > 0;
    const excludedDueToMixedGeoTypes =
      Object.keys(_countBy(downloadModalProps.reportLocations, "geo_level"))
        .length > 1 &&
      !reports.find((report) => report.template === template)
        .multipleGeoTypesAllowed;

    // throw an error for Connecticut counties for reports with BEA data
    const connecticutCounties = [
      "9011",
      "9001",
      "9007",
      "9009",
      "9015",
      "9013",
      "9005",
      "9003",
    ];
    const connecticutCountyEquivs = [
      "9110",
      "9170",
      "9180",
      "9130",
      "9190",
      "9140",
      "9120",
      "9160",
      "9150",
    ];
    const excludedDueToConnecticutCounties =
      [
        "indicators",
        "publiclands",
        "trends",
        "usfws",
        "blm",
        "fs",
        "agriculture",
        "government",
        "mining",
        "nonlabor",
        "services",
        "timber",
        "tourism",
      ].includes(template) &&
      downloadModalProps.reportLocations.some(
        (reportLocation) =>
          connecticutCounties.includes(reportLocation.id) ||
          connecticutCountyEquivs.includes(reportLocation.id)
      );

    return (
      <Box
        direction="row"
        gap="xsmall"
        pad="xsmall"
        background="#fff"
        justify="between"
      >
        <DropButton
          plain
          gap="xsmall"
          label={
            <Text size="small">
              {name}
              <ReportLevelAlerts report={template} />
            </Text>
          }
          dropContent={
            <Box width="medium" pad="small">
              <Text size="small">{summary}</Text>
            </Box>
          }
          icon={<FontAwesomeIcon icon={faAngleDown} />}
        />
        <Box>
          {errored ? (
            <ReportNotAvailableButton
              notificationText="This was an error downloading this report."
              iconType={"errored"}
            />
          ) : previouslyDownloaded ? (
            <ReportNotAvailableButton
              notificationText="This report has been downloaded already. Check your downloads folder."
              iconType={"downloaded"}
            />
          ) : downloading ? (
            <ReportNotAvailableButton
              notificationText="Downloading"
              iconType={"downloading"}
            />
          ) : excludedDueToInvalidGeos ? (
            <ReportNotAvailableButton notificationText="Some selected geographies are not supported by this report." />
          ) : excludedDueToConnecticutCounties ? (
            <ReportNotAvailableButton notificationText="In 2022, the Census Bureau adopted Connecticut's nine planning regions as county-equivalent geographic units for purposes of collecting, tabulating, and disseminating statistical data, replacing the eight counties which ceased to function as governmental and administrative entities in 1960. Reports that have data over time for these geographies are not currently available." />
          ) : excludedDueToMixedGeoTypes ? (
            <ReportNotAvailableButton notificationText="This report does not support geographies of different types." />
          ) : (
            // <Button
            //     plain
            //     disabled={previouslyDownloaded || excludedDueToInvalidGeos}
            //     icon={<FontAwesomeIcon icon={faDownload} size="1x" />}
            //     onClick={() => {
            //         onDownloadReport(name, template);
            //     }}
            // />

            <Button
              a11yTitle={"Download " + name + "Report"}
              color="heBlue-4"
              icon={<FontAwesomeIcon icon={faDownload} />}
              onClick={() => {
                onDownloadReport(name, template);
              }}
            />
          )}
        </Box>
      </Box>
    );
  };

  function MicroCards() {
    return (
      <>
        {reports
          .filter((report) => (category ? report.category === category : true))
          .map((report) => {
            let reportName = report.name;

            return (
              <MicroCard
                name={reportName}
                summary={report.summary}
                template={report.template}
                key={report.template}
                previouslyDownloaded={previouslyDownloadedReports.includes(
                  report.template
                )}
                errored={erroredReports.includes(report.template)}
                downloading={downloadingReports.includes(report.template)}
              />
            );
          })}
      </>
    );
  }
  return <MicroCards />;
}
export default ReportListCompact;
