import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";

import HeMapboxComponent, {
  ZoomInWarning,
  Popup,
  getMergedExtent,
  extentsEqual,
  CensusLayer,
  ControlContainer,
// } from "../../_shared/components/Map";
} from "@headwaters-economics/web-shared/components/Map";

import MapControlPanel from "../MapControlPanel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";

import geoDict from "../../assets/geoDict.json";
import appContext from "../../context/appContext";
import { Anchor, Box, Layer as GrommetLayer, Text } from "grommet";

import { useExtentOfGeos } from "@headwaters-economics/web-shared";
import colors from "../../assets/colors";
import { useViewport } from "@headwaters-economics/web-shared";

import { MicroQuickFacts } from "../MicroQuickFacts";

const InteractiveMap = () => {
  const {
    selectedLocations,
    activeMapLayer,
    removeSelectedLocationId,
    addSelectedLocationId,
  } = useContext(appContext);

  const [popupFeatureData, set_popupFeatureData] = useState(null);
  const [nextMapExtent, set_nextMapExtent] = useState(null);
  const [currentMapExtent, set_currentMapExtent] = useState(null);
  const mapRef = useRef();
  const { extentOfGeos: extentOfSelectedLocations } = useExtentOfGeos({
    geoIDs: selectedLocations.map((sl) => sl.id),
  });
  const onExtentChange = (e) => set_currentMapExtent(e);

  const { screenSize } = useViewport();

  const zoomToSelectedlocations = useCallback(() => {
    if (!extentOfSelectedLocations) return;
    if (selectedLocations.length === 1) {
      set_nextMapExtent(extentOfSelectedLocations);
      return;
    }
    const mergedExtent = getMergedExtent([
      currentMapExtent,
      extentOfSelectedLocations,
    ]);
    if (extentsEqual(mergedExtent, currentMapExtent) === false) {
      set_nextMapExtent(mergedExtent);
    }
  }, [extentOfSelectedLocations, currentMapExtent, selectedLocations]);

  useEffect(() => {
    zoomToSelectedlocations();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extentOfSelectedLocations]);

  
  const PopupContent = ()=>{
    return (
      <Box pad="small">
        <Box border={{ side: "bottom", size: "xsmall", color: "#ddd" }}>
          <Text weight="bold" className="condensed" color="#333">
            {popupFeatureData.feature.properties.name}
          </Text>
        </Box>
        <MicroQuickFacts format="basic" primaryGeo={popupFeatureData.feature.id} />
        {(selectedLocations.length > 1 ||
          popupFeatureData.feature.id !== selectedLocations[0].id) && (
          <Box border={{ side: "top", size: "xsmall", color: "#ddd" }}>
            <Text textAlign="center" color="#333" size="small">
              Click to {popupFeatureData.addOrRemove} location
            </Text>
          </Box>
        )}
      </Box>
    )
  }

  const LegendContent = () => {
    const activeMapLayerLabel = geoDict[activeMapLayer].labelAlt;
    if (screenSize === "mobile") return null;
    return (
      <ControlContainer
        position="lower-left"
        style={{ backgroundColor: "rgba(20, 20, 20, 0.8)", padding: "0px" }}
      >
        <Box gap="xsmall" pad="small">
          <Box direction="row" align="center" gap="small">
            <svg viewBox="0 0 16 16" width="16" height="16">
              <rect
                x="0.5"
                y="0.5"
                width="15"
                height="15"
                style={{
                  fill: "transparent",
                  strokeWidth: 1,
                  stroke: colors.geos_defaultBorder,
                }}
              />
            </svg>
            <label>
              {activeMapLayerLabel[0].toUpperCase() +
                activeMapLayerLabel.slice(1)}
            </label>
          </Box>
          <Box direction="row" align="center" gap="small">
            <svg viewBox="0 0 16 16" width="16" height="16">
              <rect
                x="0.5"
                y="0.5"
                width="15"
                height="15"
                style={{
                  fill: colors.geos_selectFill,
                  strokeWidth: 2,
                  stroke: colors.geos_selectBorder,
                }}
              />
            </svg>
            <label>{"Selected location"}</label>
          </Box>
        </Box>
      </ControlContainer>
    );
  };

  const onHover = (ftr) => {
    if (!ftr) {
      set_popupFeatureData(null);
      return;
    }

    if (!popupFeatureData || ftr.id !== popupFeatureData.id) {
      set_popupFeatureData({feature:ftr, addOrRemove: selectedLocations.some((sl) => sl.id === ftr.id) ? "remove" : "add"})
    }
  };

  const onClick = (ftr) => {
    if (!ftr) return;
    const ftrId = ftr.id;
    if (selectedLocations.length === 1 && ftrId === selectedLocations[0].id)
      return;
    const removeLoc = selectedLocations.some((sl) => sl.id === ftrId);
    if (removeLoc) {
      removeSelectedLocationId(ftrId);
    } else {
      addSelectedLocationId(ftrId);
    }
    set_popupFeatureData({feature:ftr, addOrRemove: removeLoc ? "add" : "remove"})
  };

  if (!nextMapExtent) return null;

  return (
    <Box
      ref={mapRef}
      fill
      id="map-container"
      background="#777"
      overflow="hidden"
      border={{ side: "top", color: "lightgrey" }}
    >
      <HeMapboxComponent
        extentPadding={10}
        mapStyle="mapbox://styles/mapbox/dark-v10"
        extent={nextMapExtent}
        onExtentChange={onExtentChange}
        isInteractive={true}
        attributionSide={"left"}
        clickRadius={1}
  
        cursor_isHovering="pointer"
      >
        <LegendContent />


        <Popup>
          {popupFeatureData && (
            <PopupContent />
          ) }
        </Popup>

        <CensusLayer
          key={activeMapLayer}
          uniqId={activeMapLayer}
          layerName={activeMapLayer}
          beforeId="waterway-label"
          onClick={onClick}
          onHover={onHover}
          selectedFeatures={selectedLocations}
          color_default_border={colors.geos_defaultBorder}
          color_default_fill={"transparent"}
          color_hover_border={colors.geos_focusBorder}
          color_hover_fill={colors.geos_focusFill}
          color_select_border={colors.geos_selectBorder}
          color_select_fill={colors.geos_selectFill}
          color_label={colors.geos_selectBorder}
          color_label_halo={"rgba(0,0,0,0.8)"}
        />
        <ControlContainer plain position="top-left">
          <MapControlPanel />
        </ControlContainer>
        <ZoomInWarning activeMapLayer={activeMapLayer} />
      </HeMapboxComponent>

      {mapRef && selectedLocations.length > 50 && (
        <GrommetLayer
          animation={false}
          modal={false}
          plain
          position={"bottom"}
          responsive={false}
          target={mapRef.current}
          margin={{ bottom: "10px" }}
        >
          <Box
            direction="row"
            pad="xsmall"
            gap="xxsmall"
            align="start"
            background={{ color: "light-1", opacity: 0.9 }}
            round="3px"
            width={{ max: "large" }}
            className="map-control"
          >
            <Box width="70px" align="center">
              <FontAwesomeIcon
                color="#FFAA15"
                icon={faExclamationTriangle}
                size="lg"
              />
            </Box>
            {screenSize === "mobile" ? (
              <Text size="xsmall">You have selected too many geographies.</Text>
            ) : (
              <Text size="small">
                You have selected too many geographies. Reduce your selection to
                50 or less. Please{" "}
                <Anchor href="https://headwaterseconomics.org/contact">
                  contact us
                </Anchor>{" "}
                for a custom report.
              </Text>
            )}
          </Box>
        </GrommetLayer>
      )}
    </Box>
  );
};

export default InteractiveMap;
