import React, { useContext } from "react";
import { getRelativeHeadingSize } from "@headwaters-economics/web-shared";
import LocationPicker  from "@headwaters-economics/web-shared/components/LocationPicker";
import { useViewport } from "@headwaters-economics/web-shared";
import appContext from "../context/appContext";
import { Box, Text, Button } from "grommet";
import coverPhoto from "../assets/images/startscreen2.jpg";
import AppMenu from "../components/AppMenu";
import heLogoIcon from "../assets/images/HE-LogoIconWhite.svg";

function StartScreen() {
  const { setSearchLocation } = useContext(appContext);
  const { screenSize, appWidth } = useViewport();

  return (
    <Box
      fill
      background={{
        size: "cover",
        image: "url(" + coverPhoto + ")",
        color: "rgba(20,20,20,.3)",
      }}
      className="home-screen"
      height={{ min: "100vh" }}
    >
      <Box fill background="rgba(20,20,20,.25)">
        <Box
          justify="between"
          direction="row"
          align="center"
          height={{ min: "fit-content" }}
        >
          <Box direction="row" align="center" pad="xsmall">
            <Box
              border={{ side: "right", color: "light-3", size: "xsmall" }}
              pad={{ right: "6px", left: "3px" }}
              align="center"
              width={{ min: "fit-content" }}
            >
              <Button plain href="/">
                <img
                  className="logo-icon"
                  height={screenSize === "mobile" ? "30px" : "45px"}
                  width={screenSize === "mobile" ? "30px" : "45px"}
                  src={heLogoIcon}
                  alt="Headwaters logo"
                />
              </Button>
            </Box>
            <Box
              direction="row"
              pad={{ left: "4px", right: "small" }}
              margin={{ bottom: "-2px" }}
              width={{ min: "fit-content" }}
            >
              <Button plain href="/apps/economic-profile-system">
                <Text
                  size={screenSize === "mobile" ? "large" : "xxlarge"}
                  color="#fff"
                >
                  <Text
                    size={screenSize === "mobile" ? "large" : "xxlarge"}
                    weight="bold"
                  >
                    Economic
                  </Text>{" "}
                  Profile System
                </Text>
              </Button>
            </Box>
          </Box>
          <Box
            background="light-1"
            margin="xsmall"
            elevation="small"
            width="45px"
            height="45px"
            align="center"
            justify="center"
            round="100%"
          >
            <AppMenu
              customItems={[
                {
                  label: "About the Economic Profile System",
                  href: "/tools/economic-profile-system/about-eps/",
                },
                {
                  label: "Legacy Version",
                  href: "/apps/legacy-economic-profile-system/",
                },
              ]}
            />
          </Box>
        </Box>
        <Box fill direction="column" align="center" justify="center">
          <Box
            width="xlarge"
            pad="small"
            margin={{ top: appWidth < 1000 ? "large" : "-10%" }}
          >
            <Text
              style={{ textShadow: "0 0  3px #00000088" }}
              margin={{ bottom: "small" }}
              weight="bold"
              textAlign="start"
              color="#fff"
              size={getRelativeHeadingSize("xxxlarge", screenSize)}
              className="condensed"
            >
              Get socioeconomic profiles
            </Text>
            <Box
              background={"brand"}
              direction="row"
              round="small"
              gap="xsmall"
              pad={{ vertical: "xsmall", horizontal: "small" }}
              margin={{ bottom: "small" }}
            >
              <Text weight={"bolder"}>NEW!</Text>{" "}
              <Text>
                The latest American Community Survey data are now available in
                EPS!
              </Text>
            </Box>

            <LocationPicker
              round="xsmall"
              onSelection={setSearchLocation}
              placeholder={
                "Search by community, Indigenous area, county, or state"
              }
              geoTypes={[
                "he-place",
                "he-county",
                "he-state",
                "he-nation",
                "he-tribal",
              ]}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
export default StartScreen;
