import React, { useReducer, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Text } from "grommet";
import AppContext from "./appContext";
import { getData } from "@headwaters-economics/web-shared";
import reports from "../assets/reports.json";
import geoDict from "../assets/geoDict.json";
import useQuickFacts from "../hooks/useEpsQuickFacts";
import { useGeoMetadata } from "@headwaters-economics/web-shared";
// import { useGeoMetadata } from "../_shared";

import useDataAlerts from "../hooks/useDataAlerts";
import _isEqual from "lodash/isEqual";

const initialState = {
  searchedLocation: null,
  selectedLocationIds: [],
  selectedLocations: [],
  associatedCounty: null,
  primaryQuickFacts: null,
  notifications: null,
  downloadModalProps: { isVisible: false },
  isMultipleGeosViewVisible: false,
  benchmarkGeo: { id: "0", label: "United States", name: "United States" },
  combinedAreaName: "",
  activeMapLayer: "county",
  latestPrintParameters: "",
};

const AppProvider = ({ children, storybookState }) => {
  const [state, dispatch] = useReducer(
    appReducer,
    storybookState ? { ...initialState, ...storybookState } : initialState
  );
  const navigate = useNavigate();
  const urlPath = useLocation();
  const grommetRoot = useRef();

  //data fetch hooks
  const { geoMetadata, status: geoMetadataStatus } = useGeoMetadata({
    geoIDs:
      state.selectedLocationIds.length > 0 ? state.selectedLocationIds : null,
  });

  const { dataAlerts } = useDataAlerts({ geoIDs: state.selectedLocationIds });
  const { quickFactsData, status: quickFactsDataStatus } = useQuickFacts({
    geoIDs: [
      state.benchmarkGeo.id,
      state.associatedCounty ? state.associatedCounty.id : null,
    ]
      .concat(state.selectedLocations.map((selectedLoc) => selectedLoc.id))
      .filter((id) => id),
  });

  useEffect(() => {
    const locParts = urlPath.pathname.split("/");
    if (locParts[1]) {
      const newLocationIds = locParts[1].split("+").filter((id) => id !== "");
      const endChar = locParts[1].charAt(locParts[1].length - 1);
      const switchView =
        newLocationIds.length === 1 &&
        ((endChar === "+" && !state.isMultipleGeosViewVisible) ||
          (endChar !== "+" && state.isMultipleGeosViewVisible));
      if (!_isEqual(newLocationIds, state.selectedLocationIds) || switchView) {
        dispatch({
          type: "SET_SELECTED_LOCATION_IDS",
          to: newLocationIds,
          isMultipleGeosViewVisible:
            newLocationIds.length > 1 ||
            (newLocationIds.length === 1 && endChar === "+"),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlPath]);

  useEffect(() => {
    if (geoMetadataStatus === "fetched") {
      dispatch({ type: "SET_SELECTED_LOCATIONS", newGeoMetadata: geoMetadata });
    }
  }, [geoMetadataStatus, geoMetadata]);

  useEffect(() => {
    //geo level alerts
    let geoLevelAlerts = new Set();
    Object.keys(dataAlerts).forEach((geoId) => {
      if (dataAlerts[geoId] !== "fetching") {
        dataAlerts[geoId].forEach((alert) => {
          if (!alert.report) {
            geoLevelAlerts.add(alert.message);
          }
        });
      }
    });
    geoLevelAlerts = [...geoLevelAlerts];

    // report level alerts and errors
    let reportLevel = {};
    reports.forEach((report) => {
      //warning that are specific to a report
      let alerts = new Set();
      Object.keys(dataAlerts).forEach((geoId) => {
        if (dataAlerts[geoId] !== "fetching") {
          dataAlerts[geoId].forEach((alert) => {
            if (alert.report && alert.report === report.template) {
              alerts.add(alert.message);
            }
          });
        }
      });
      alerts = [...alerts];

      //errors that block a report from being run
      let errors = [];
      if (state.selectedLocations.length > 1) {
        const invalidLocations = state.selectedLocations.filter(
          (loc) =>
            loc.validReports && !loc.validReports.includes(report.template)
        );
        if (invalidLocations.length > 0) {
          errors.push(
            <Text size="small">
              The following locations are not supported by this report type and
              will not be included:{" "}
              <Text size="small" weight="bold">
                {invalidLocations.map((loc) => loc.name).join("; ")}
              </Text>
              . Valid geography levels for this report include:{" "}
              <Text size="small" style={{ fontStyle: "italic" }}>
                {report.geoTypes
                  .map((geoType) => geoDict[geoType].label)
                  .join(", ")}
              </Text>
              .
            </Text>
          );
        }
      }
      reportLevel[report.template] = { alerts, errors };
    });

    dispatch({
      type: "SET_NOTIFICATIONS",
      to: { geoLevelAlerts, reportLevel },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataAlerts]);

  // update the quick facts data and the associated county when the searchLocation changes
  useEffect(() => {
    if (
      state.selectedLocations.length === 1 &&
      ["place"].includes(state.selectedLocations[0].geo_level)
    ) {
      getData("fetchGeosIntersectingGeo", {
        inGeoID: state.selectedLocations[0].id,
        outGeoType: "he-county",
      }).then((results) => {
        const associatedCounty =
          results.selectedGeos.length === 1
            ? results.selectedGeos[0]
            : results.selectedGeos.reduce(
                (previousValue, currentValue) =>
                  currentValue.prop_geo1_in_geo2 >
                  previousValue.prop_geo1_in_geo2 //find the county that covers the highest proportion of the place
                    ? currentValue
                    : previousValue,
                results.selectedGeos[0]
              );
        dispatch({ type: "SET_ASSOCIATED_COUNTY", to: associatedCounty });
      });
    } else if (
      state.selectedLocations.length === 1 &&
      ["tribal"].includes(state.selectedLocations[0].geo_level)
    ) {
      getData("fetchGeosIntersectingGeo", {
        inGeoID: state.selectedLocations[0].id,
        outGeoType: "he-county",
      }).then((results) => {
        let associatedCounty = null;
        results.selectedGeos.forEach((result) => {
          if (result.prop_geo1_in_geo2 > 0.5) {
            associatedCounty = result;
          }
        });
        dispatch({ type: "SET_ASSOCIATED_COUNTY", to: associatedCounty });
      });
    } else {
      dispatch({ type: "SET_ASSOCIATED_COUNTY", to: null });
    }
  }, [state.selectedLocations]);

  useEffect(() => {
    const idStr =
      state.selectedLocationIds.join("+") +
      (state.selectedLocationIds.length === 1 && state.isMultipleGeosViewVisible
        ? "+"
        : "");
    navigate("/" + idStr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selectedLocationIds, state.isMultipleGeosViewVisible]);

  const setBenchmarkGeo = (newVal) => {
    dispatch({ type: "SET_BENCHMARK_GEO", to: newVal });
  };

  const setCombinedAreaName = (newVal) => {
    dispatch({ type: "SET_COMBINED_AREA_NAME", to: newVal });
  };

  const setActiveMapLayer = (newVal) => {
    dispatch({ type: "SET_ACTIVE_MAP_LAYER", to: newVal });
  };

  const setSearchLocation = (location) => {
    dispatch({ type: "SET_SEARCH_LOCATION", to: location });
  };

  const addSelectedLocationId = (locationId) => {
    dispatch({ type: "ADD_SELECTED_LOCATION_ID", locationId: locationId });
  };

  const removeSelectedLocationId = (locationId) => {
    dispatch({ type: "REMOVE_SELECTED_LOCATION_ID", locationId: locationId });
  };

  const setDownloadModalProps = (props) => {
    dispatch({ type: "SET_DOWNLOAD_MODAL_PROPS", to: props });
  };

  // const setLatestPrintParameters = (printParameters) => {
  //     dispatch({ type: "SET_LATEST_PRINT_PARAMETERS", to: printParameters });
  // };

  const setIsMultipleGeosViewVisible = (isMultipleGeosViewVisible) => {
    dispatch({
      type: "SET_IS_MULTIPLE_GEOS_VIEW_VISIBLE",
      to: isMultipleGeosViewVisible,
    });
  };

  return (
    <AppContext.Provider
      value={{
        searchedLocation: state.searchedLocation,
        setSearchLocation,
        selectedLocations: state.selectedLocations,
        addSelectedLocationId,
        removeSelectedLocationId,
        primaryQuickFacts: state.primaryQuickFacts,
        associatedCounty: state.associatedCounty,
        downloadModalProps: state.downloadModalProps,
        setDownloadModalProps,
        dataAlerts,
        notifications: state.notifications,
        isMultipleGeosViewVisible: state.isMultipleGeosViewVisible,
        setIsMultipleGeosViewVisible: setIsMultipleGeosViewVisible,
        quickFactsData: quickFactsData,
        quickFactsDataStatus: quickFactsDataStatus,
        benchmarkGeo: state.benchmarkGeo,
        setBenchmarkGeo,
        combinedAreaName: state.combinedAreaName,
        setCombinedAreaName,
        activeMapLayer: state.activeMapLayer,
        setActiveMapLayer,
        grommetRoot,
        latestPrintParameters: state.latestPrintParameters,
        // setLatestPrintParameters
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const appReducer = (state, action) => {
  switch (action.type) {
    case "SET_SEARCH_LOCATION":
      return {
        ...state,
        searchedLocation: action.to,
        selectedLocationIds: [action.to.id],
        associatedCounty: null,
        isMultipleGeosViewVisible: false,
      };
    case "SET_SELECTED_LOCATION_IDS":
      return {
        ...state,
        selectedLocationIds: action.to,
        associatedCounty: null,
        isMultipleGeosViewVisible: action.isMultipleGeosViewVisible,
      };
    case "SET_SELECTED_LOCATIONS":
      const selectedLocations = state.selectedLocationIds
        .filter((id) => action.newGeoMetadata[id])
        .map((id) => ({
          id: id,
          ...action.newGeoMetadata[id],
          validReports: reports
            .filter((report) =>
              report.geoTypes.includes(action.newGeoMetadata[id].geo_level)
            )
            .map((report) => report.template),
        }));
      return {
        ...state,
        selectedLocations,
        activeMapLayer:
          selectedLocations.length === 1
            ? selectedLocations[0].geo_level
            : state.activeMapLayer,
        searchedLocation: state.searchedLocation || selectedLocations[0],
      };
    case "ADD_SELECTED_LOCATION_ID":
      if (state.selectedLocationIds.some((id) => id === action.locationId))
        return state;
      return {
        ...state,
        selectedLocationIds: [...state.selectedLocationIds, action.locationId],
      };
    case "REMOVE_SELECTED_LOCATION_ID":
      return {
        ...state,
        selectedLocationIds: state.selectedLocationIds.filter(
          (id) => id !== action.locationId
        ),
      };
    case "SET_ASSOCIATED_COUNTY":
      if (!action.to) {
        return {
          ...state,
          associatedCounty: null,
        };
      }
      return {
        ...state,
        associatedCounty: {
          ...action.to,
          validReports: reports
            .filter((report) => report.geoTypes.includes("county"))
            .map((report) => report.template),
          geo_level: "county",
        },
      };
    case "SET_PRIMARY_QUICK_FACTS":
      return {
        ...state,
        primaryQuickFacts: action.to,
      };
    case "SET_DOWNLOAD_MODAL_PROPS":
      return {
        ...state,
        downloadModalProps: action.to,
      };
    case "SET_IS_MULTIPLE_GEOS_VIEW_VISIBLE":
      return {
        ...state,
        isMultipleGeosViewVisible: action.to,
      };
    case "SET_LATEST_PRINT_PARAMETERS":
      return {
        ...state,
        latestPrintParameters: action.to,
      };
    case "SET_BENCHMARK_GEO":
      return {
        ...state,
        benchmarkGeo: { ...action.to, name: action.to.label },
      };
    case "SET_COMBINED_AREA_NAME":
      return {
        ...state,
        combinedAreaName: action.to,
      };
    case "SET_ACTIVE_MAP_LAYER":
      return {
        ...state,
        activeMapLayer: action.to,
      };
    case "SET_NOTIFICATIONS":
      return {
        ...state,
        notifications: action.to,
      };
    default:
      throw new Error();
  }
};

export default AppProvider;
